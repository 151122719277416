<template>
  <div>
    <bb-table
      ref="bbTable"
      class="rounded-md table-container"
      :headers="headers"
      :data="infringements"
      :is-loading="false"
      :scroll-y="true"
      :sticky-header="true"
      :apply-initial-sort="false"
      table-height="530px"
      max-table-height="530px"
    >
      <template #rows="{ tableData }">
        <infringement-summary-rows
          :data="tableData"
          @infringement-action="$emit('infringement-action', $event)"
        />
      </template>
    </bb-table>
  </div>
</template>

<script>
import BbTable from '@/components/table/BbTable.vue'
import InfringementSummaryRows from '@/views/site/search/competitor_monitoring/infringements_detector/competitor_infringement_summary/InfringementSummaryRows.vue'
export default {
  name: 'InfringementSummaryTable',
  components: { BbTable, InfringementSummaryRows },
  props: {
    infringements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'competitor_name',
          label: 'Ads',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'keywords',
          label: 'Keywords',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'views',
          label: 'Views',
          sortable: true,
          sorting: null,
          position: 'center',
        },
        {
          value: 'device',
          label: 'Device',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'date-and-time',
          label: 'Date & Time',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'action',
          label: '',
          sortable: false,
          sorting: null,
          position: 'center',
        },
      ],
    }
  },
  computed: {},
}
</script>

<style scoped lang="scss"></style>
