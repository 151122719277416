<template>
  <div class="my-6 relative">
    <div
      v-if="isTableLoading"
      class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <brightbid-loader>
        <p>{{ tableLoadingMessage }}</p>
      </brightbid-loader>
    </div>
    <bb-table
      ref="bbTable"
      class="border rounded-md bg-white"
      :headers="headers"
      :data="infringements"
      :scroll-y="true"
      :sticky-header="true"
      :default-sorting-order="sortingOrder"
      table-height="calc(100vh - 450px)"
      @sort-table="$emit('sort-table')"
    >
      <template #rows="{ tableData }">
        <infringement-table-rows
          :data="tableData"
          @show-more="$emit('open-infringement-summary', $event)"
          @infringement-action="$emit('infringement-action', $event)"
        />
      </template>
    </bb-table>
  </div>
</template>

<script>
import BbTable from '@/components/table/BbTable.vue'
import InfringementTableRows from '@/views/site/search/competitor_monitoring/infringements_detector/all_infringements/InfringementTableRows.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'

export default {
  name: 'InfringementTable',
  components: { BrightbidLoader, BbTable, InfringementTableRows },
  props: {
    infringements: {
      type: Array,
      required: true,
    },
    isTableLoading: {
      type: Boolean,
      default: false,
    },
    tableLoadingMessage: {
      type: String,
      default: 'Loading...',
    },
  },
  data() {
    return {
      sortingOrder: { order: 'ASCENDING', column: 'competitorName' },
      headers: [
        {
          value: 'competitorName',
          label: 'Ads',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'keywords',
          label: 'Keywords',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'views',
          label: 'Views',
          sortable: true,
          sorting: null,
          position: 'center',
        },
        {
          value: 'competitor',
          label: 'Competitor',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'devices',
          label: 'Devices',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'date-and-time',
          label: 'Date & Time',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'action',
          label: 'Action',
          sortable: false,
          sorting: null,
          position: 'center',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.table-container {
  height: calc(100vh - 200px) !important;
}
</style>
